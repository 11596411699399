exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bundles-js": () => import("./../../../src/pages/bundles.js" /* webpackChunkName: "component---src-pages-bundles-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-emails-js": () => import("./../../../src/pages/emails.js" /* webpackChunkName: "component---src-pages-emails-js" */),
  "component---src-pages-everything-packages-js": () => import("./../../../src/pages/everything-packages.js" /* webpackChunkName: "component---src-pages-everything-packages-js" */),
  "component---src-pages-home-packages-js": () => import("./../../../src/pages/home-packages.js" /* webpackChunkName: "component---src-pages-home-packages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiries-js": () => import("./../../../src/pages/inquiries.js" /* webpackChunkName: "component---src-pages-inquiries-js" */),
  "component---src-pages-internet-js": () => import("./../../../src/pages/internet.js" /* webpackChunkName: "component---src-pages-internet-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-ordernow-js": () => import("./../../../src/pages/ordernow.js" /* webpackChunkName: "component---src-pages-ordernow-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-phone-js": () => import("./../../../src/pages/phone.js" /* webpackChunkName: "component---src-pages-phone-js" */),
  "component---src-pages-popular-packages-js": () => import("./../../../src/pages/popular-packages.js" /* webpackChunkName: "component---src-pages-popular-packages-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

